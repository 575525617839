import React from 'react'

const SIZE = 800

// TODO remove use of inline style properties and use tachyons
const Record = ({ onClick, playing, artistName, trackName, className }) => (
  <svg
    onClick={onClick}
    style={{ maxHeight: `calc(100vh - 9.5em)`, enableBackground: `new 0 0 ${SIZE} ${SIZE}` }}
    className={`db h-100 w-100 ${playing ? 'spin ' : ''}${className}`}
    role='img'
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox={`0 0 ${SIZE} ${SIZE}`}
    xmlSpace='preserve'
  >
    <circle
      className='recordstroke'
      cx='401'
      cy='401'
      r='340'
    />

    <path
      id='SVGID_x5F_1_x5F_'
      style={{ fill: 'none' }}
      d='M394.6,228.3c95.7,0.2,173.1,75.3,172.9,167.7s-77.9,167.2-173.6,167
    c-95.7-0.2-173.1-75.3-172.9-167.7S254.9,184.1,350.6,184.3z'
    />
    <text>
      <textPath xlinkHref='#SVGID_x5F_1_x5F_' startOffset='0%'>
        <tspan style={{ fontSize: '62px' }}>{trackName}</tspan>
      </textPath>
    </text>
    <line
      style={{ fill: 'none' }}
      x1='177'
      y1='184.3'
      x2='523.1'
      y2='519.1'
    />
    <polygon
      className={`${playing ? 'dn' : ''}`}
      points='350.3,317.4 350.3,465.7 455.7,390'
    />
  </svg>
)

export default Record
