import React from 'react'
import Layout from '../components/layout'
import Player from '../components/Player'

const IndexPage = () => (
  <Layout className='h-100'>
    <Player />
  </Layout>
)

export default IndexPage
