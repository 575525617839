import Debug from 'debug'
import React from 'react'
import Chance from 'chance'
import ReactPlayer from 'react-player/lib/players/FilePlayer'
import Record from './Record'
import tracks from './tracks.json'
import SvgSkipNext from './sharp-skip_next-24px.svg'
import SvgPause from './sharp-pause-24px.svg'
import ExternalLink from './ExternalLink'

const debug = Debug('iw:components:player')

const TextRow = ({ children }) => (<div className='flex pa2 flex-row justify-between'>{children}</div>)

const chance = new Chance(Math.random)

class Player extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {
    this.handleShuffle()
  }
  handleRecordClick () {
    !this.state.playing && this.handlePlay()
  }
  handlePause () {
    debug(`🎷 handlePause`)
    this.setState(state => ({ ...state, playing: false }))
  }
  handlePlay () {
    debug(`🎷 handlePlay`)
    this.setState(state => ({ ...state, playing: true }))
  }
  handleMarqueeMaybe (e) {
    // TODO http://jsfiddle.net/MaY5A/1/
    debug('scrollWidth', e.target.scrollWidth)
    debug('innerText', e.target.innerText)
    debug('et', e.target.offsetWidth)
  }
  handleShuffle () {
    const trackNumber = chance.natural({ min: 1, max: tracks.length })
    debug(`🎷 Shuffling: ${trackNumber} of ${tracks.length}`)
    this.setState(tracks[trackNumber])
  }
  // TODO remove lazy click handler div wrapper
  render () {
    return (
      <div className='h-100 f4 f3-ns f2-m f1-l'>
        <TextRow>
          <div className='nowrap'>
            <span onClick={() => this.handleShuffle()}>
              <SvgSkipNext cursor='pointer' height='1.5em' width='1.5em' />
            </span>
            {this.state.playing && (
              <span onClick={() => this.handlePause()}>
                <SvgPause cursor='pointer' height='1.5em' width='1.5em' />
              </span>
            )}
          </div>
          <div className='nowrap w-100 marquee ml3 pt1 pt2-ns'>
            <ExternalLink href={this.state.artistUrl}>
              <span className='marquee-inner'>{this.state.artistName}</span>
            </ExternalLink>
          </div>
        </TextRow>
        <div className='pa3'>
          <Record
            onClick={() => this.handleRecordClick()}
            artistName={this.state.playing && this.state.artistName}
            trackName={this.state.playing && this.state.trackName}
            playing={this.state.playing}
          />
        </div>
        <TextRow>
          <ExternalLink href={this.state.url}>{this.state.recordDescription}</ExternalLink>
        </TextRow>
        <ReactPlayer
          url={this.state.fileName}
          onBuffer={() => debug('Buffer')}
          onEnded={() => {
            debug('Ended')
            this.handleShuffle()
          }}
          onError={console.error}
          onPause={() => debug('Pause')}
          onPlay={() => debug('Play')}
          onProgress={p => debug('Progress', p)}
          onReady={() => debug('Ready')}
          playing={this.state.playing}
          file={{ forceAudio: true }}
          controls={false}
          width='100%'
          height='0px'
        >
          <span aria-label='mad face' role='img'>{'😤 '}</span>
          Your browser does not support the <code>audio</code> element.
        </ReactPlayer>
      </div>
    )
  }
}

export default Player
